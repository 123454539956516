import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import MessageAndErrors from "components/MessageAndErrors";
import ScrollTop from "components/ScrollTop";
import { getAppInitialData, getInitialData, loadUser } from "redux/actions";
import Routes from "routes";

const App = () => {

  const dispatch = useDispatch();  
  const { isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getAppInitialData());
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if(isAuthenticated){
      dispatch(getInitialData());
    }
  }, [isAuthenticated, dispatch]);  

  window.onload = function() {
    var numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(function(input) {
      input.addEventListener('mousewheel', function(e) {
        e.preventDefault();
      });
    });
  };

  return(
    <>
      <ScrollTop>
        <Routes />
      </ScrollTop>
      <MessageAndErrors/>
    </>
  )
};

export default App;