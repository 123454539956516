import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';

const icons = {  
  DashboardOutlinedIcon,  
  HowToRegOutlinedIcon,
  TokenOutlinedIcon
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1]      
    },
    {
      id: "appointment",
      title: "My Appointments",
      type: "item",
      url: "/appointment",
      icon: icons.HowToRegOutlinedIcon,
      breadcrumbs: true,
      roles: [1]      
    },
    {
      id: "token",
      title: "My Tokens",
      type: "item",
      url: "/token",
      icon: icons.TokenOutlinedIcon,
      breadcrumbs: true,
      roles: [1]      
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1],
      display: false
    },    
    {
      id: "profile-edit",
      title: "Edit Profile",
      type: "item",
      url: "/profile/edit",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1],
      display: false
    }    
  ]
};

export default dashboard;