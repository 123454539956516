import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  appointmentData: null,
  appointment: null,
  tokens: [] 
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) {  
    case dataConstants.GET_APPOINTMENTS_REQUEST:
    case dataConstants.GET_APPOINTMENT_REQUEST:
    case dataConstants.GET_TOKENS_REQUEST:
      return {
        ...state,
        loading: true,
      };
      
    case dataConstants.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointmentData: action.payload,
        loading: false,
      };
      
    case dataConstants.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
      };

    case dataConstants.GET_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.payload,
        loading: false,
      };

    case dataConstants.GET_APPOINTMENTS_FAILURE:
    case dataConstants.GET_APPOINTMENT_FAILURE:
    case dataConstants.GET_TOKENS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;