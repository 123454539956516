import { combineReducers } from "redux";

import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import appReducer from "./app.reducer";
import dataReducer from "./data.reducer";

const rootReducer = combineReducers({    
  app: appReducer,   
  data: dataReducer,
  menu: menuReducer,    
  user: userReducer,
});

export default rootReducer;