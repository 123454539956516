import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,  
  categories: [], 
  hospitals: [],
  redirect: "" 
};

const appReducer = (state = initState, action) => {    
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:            
    case appConstants.GET_APP_INITIAL_DATA_REQUEST:
    case appConstants.UPDATE_PROFILE_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_APP_INITIAL_DATA_SUCCESS:
      return {
        ...state,    
        categories: action.payload.categories,      
        loading: false,
      };

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        hospitals: action.payload.hospitals,
        loading: false,
      };    
    
    case appConstants.UPDATE_PROFILE_SUCCESS:
    case appConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.GET_INITIAL_DATA_FAILURE:
    case appConstants.GET_APP_INITIAL_DATA_FAILURE:
    case appConstants.UPDATE_PROFILE_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:           
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;