import { Box } from "@mui/material";

const Search = () => {  
  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      
    </Box>
  );
}

export default Search;