import { appConstants } from "redux/constants";
import Axios from "utils/axios";
import { loadUser } from "./user.action";

export const getAppInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_APP_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/app/patient/initial-data`);            
      dispatch({ 
        type: appConstants.GET_APP_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_APP_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/patient/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateProfile = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PROFILE_REQUEST });
      const { data } = await Axios.put(`/auth/patient/profile`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PROFILE_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
      dispatch(loadUser())
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PROFILE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/patient/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}